<template>
  <DrillingTableSettingsStyled>
    <DurationFormatMolecule :widgetSettings="widgetSettings" @changeWidgetSettings="$emit('changeWidgetSettings', $event)" />
  </DrillingTableSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import DurationFormatMolecule from '@/components/Atomic/Molecules/DurationFormatMolecule'

const DrillingTableSettingsStyled = styled('div')``

export default {
  components: {
    DrillingTableSettingsStyled,
    DurationFormatMolecule,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
  },
}
</script>
